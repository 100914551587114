<template>
  <el-dialog
      :append-to-body="true"
      :visible="dialogShow"
      title="编辑直客链接"
      width="600px"
      :before-close="cancel"
      class="addlinks-dialog"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-position="labelPosition"
      label-width="120px"
    >
      <!--
        编辑直客链接,
        1.只显示上级支持的大组
        2.只能修改不能清空
      -->
      <el-form-item
          label="代理"
      >
        <el-input
            disabled
            placeholder="请输入"
            v-model="agencyName"
        ></el-input>
      </el-form-item>
      <el-form-item
          label="级别名称"
          prop="name"
      >
        <el-input
            disabled
            placeholder="请输入"
            v-model="form.name"
            maxlength="10"
            show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item
          v-if="stdInfo.id"
          label="STD分组"
          prop="stdName">
        <el-select
            placeholder="请选择"
            v-model="form.stdName"
        >
          <el-option
              v-for="(item,index) in stdInfo.groupDetail"
              :key="index"
              :label="item.desc"
              :value="item.groupDetailId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
          v-if="proInfo.id"
          label="PRO分组"
          prop="proName"
      >
        <el-select
            placeholder="请选择"
            v-model="form.proName"
        >
          <el-option
              v-for="(item,index) in proInfo.groupDetail"
              :key="index"
              :label="item.desc"
              :value="item.groupDetailId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
          v-if="ecnInfo.id"
          label="ECN分组"
          prop="ecnName"
      >
        <el-select
            placeholder="请选择"
            v-model="form.ecnName"
        >
          <el-option
              v-for="(item,index) in ecnInfo.groupDetail"
              :key="index"
              :label="item.desc"
              :value="item.groupDetailId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
          v-if="centInfo.id"
          label="Cent分组"
          prop="centName"
      >
        <el-select
            placeholder="请选择"
            v-model="form.centName"
        >
          <el-option
              v-for="(item,index) in centInfo.groupDetail"
              :key="index"
              :label="item.desc"
              :value="item.groupDetailId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
          v-if="stpInfo.id"
          label="Cent分组"
          prop="centName"
      >
        <el-select
            placeholder="请选择"
            v-model="form.stpName"
        >
          <el-option
              v-for="(item,index) in stpInfo.groupDetail"
              :key="index"
              :label="item.desc"
              :value="item.groupDetailId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
          label="注册开户默认"
          prop="defaultGroup"
      >
        <!--如禁用了部分分组,这里应该需要同步-->
        <el-radio-group
            v-model="form.defaultGroup"
        >
          <el-radio
              v-if="stdInfo.id"
              :label="1"
          >STD</el-radio>
          <el-radio
              v-if="proInfo.id"
              :label="2"
          >PRO</el-radio>
          <el-radio
              v-if="ecnInfo.id"
              :label="3"
          >ECN</el-radio>
          <el-radio
              v-if="centInfo.id"
              :label="4"
          >CENT</el-radio>
          <el-radio
              v-if="stpInfo.id"
              :label="5"
          >STP</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div class="dialog-footer" slot="footer">
<!--      <el-button type="text" @click="cancel">取消</el-button>-->
      <el-button
          :loading="loading"
          type="primary"
          @click="sureHandle"
      >确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { editCustomerLink, queryGroupDetailAll,queryGroupDetail,queryAgentGroupList } from '@/api/ib/ibMerchantInfo';
export default {
  name: 'editLinks',
  props: {
    dialogShow: {
      type: Boolean,
      default: false
    },
    agencyName:{
      type: String,
      default: '',
    },
    formData:{
      type: Object,
      default(){
        return {
          linkId: '',
          name: '',
          stdName: '',
          proName: '',
          ecnName: '',
          centName: '',  // 美分
          stpName: '',
          defaultGroup: '',
        }
      }
    },
    custId:{
    },
  },
  data() {
    return {
      loading: false,
      agencyNameSelf: '',
      form: {
        agencyId: '',
        name: '',
        stdName: '',
        proName: '',
        ecnName: '',
        centName: '',  // 美分
        stpName: '',  // stp
        defaultGroup: '',
      },
      rules:{
        agencyId:[
          { required: true, message: '请输入姓名/ID', trigger: 'change' },
        ],
        name:[
          { required: true, message: '请输入直客级别名称', trigger: 'blur' },
        ],
        stdName: [
          { required: true, message: '请选择STD分组', trigger: 'change' },
        ],
        proName: [
          { required: true, message: '请选择PRO分组', trigger: 'change' },
        ],
        ecnName: [
          { required: true, message: '请选择ECN分组', trigger: 'change' },
        ],
        centName: [
          { required: true, message: '请选择cent分组', trigger: 'change' },
        ],
        stpName: [
          { required: true, message: '请选择stp分组', trigger: 'change' },
        ],
        defaultGroup:[
          { required: true, message: '请选择注册开户默认', trigger: 'change' },
        ]
      },
      stdInfo:{
        id: 0,
        groupDetail:null,
      },
      proInfo:{
        id: 0,
        groupDetail: null,
      },
      ecnInfo:{
        id: 0,
        groupDetail: null,
      },
      centInfo:{
        id: 0,
        groupDetail: null,
      },
      stpInfo:{
        id: 0,
        groupDetail: null,
      },
      labelPosition: 'right',
      curLinkId: '',
      tCustGroupList: [],
    }
  },
  watch:{
    dialogShow(newVal){
      if(newVal){
        this.$refs.form && this.$refs.form.clearValidate();
        if(this.formData.linkId){
          this.form.name = this.formData.name;
        }
      }
    },
    agencyName(newVal,odlVal){
      if(newVal){
        this.agencyNameSelf = newVal;
      }
    },
    custId(newVal){
      if(newVal){
        // this.getGroupList(newVal);
      }
    },
    formData:{
      handler(newVal){
        // 添加的时候还残留编辑过的级别名称 todo
        this.form.name = newVal.name;
        this.curLinkId = newVal.linkId;
        this.groupReset();
        this.getGroupList(this.custId,(res) => {
          this.getSmallDetailsGroupList((res) => {
            queryGroupDetail({
              linkId: this.formData.linkId,
            }).then((res) => {
              this.form.defaultGroup = res.statistics.defaultGroupId;
              this.tCustGroupList = res.content;
              for (let i = 0; i < res.content.length; i++) {
                if(this.stdInfo.id){
                  if(res.content[i].groupId == this.stdInfo.id){
                    this.form.stdName = res.content[i].groupDetailId + '';
                  }
                }
                if(this.proInfo.id){
                  if(res.content[i].groupId == this.proInfo.id){
                    this.form.proName = res.content[i].groupDetailId + '';
                  }
                }
                if(this.ecnInfo.id){
                  if(res.content[i].groupId == this.ecnInfo.id){
                    this.form.ecnName = res.content[i].groupDetailId + '';
                  }
                }
                if(this.centInfo.id){
                  if(res.content[i].groupId == this.centInfo.id){
                    this.form.centName = res.content[i].groupDetailId + '';
                  }
                }
                if(this.stpInfo.id){
                  if(res.content[i].groupId == this.stpInfo.id){
                    this.form.stpName = res.content[i].groupDetailId + '';
                  }
                }
              }
            });
          });
        })
      },
      deep:true,
    },
  },
  computed: {
  },
  methods: {
    groupReset(){
      this.stdInfo = {
        id: 0,
        groupDetail: null,
      };
      this.proInfo = {
        id: 0,
        groupDetail: null,
      };
      this.ecnInfo = {
        id: 0,
        groupDetail: null,
      };
      this.centInfo = {
        id: 0,
        groupDetail: null,
      };
      this.stpInfo = {
        id: 0,
        groupDetail: null,
      };
    },
    getGroupList(custId,callback){
      queryAgentGroupList({
        custId: custId,
      }).then((res) => {
        for (let i = 0; i < res.length; i++) {
          if(res[i].groupName == 'STD'){
            this.stdInfo = res[i];
          }
          if(res[i].groupName == 'PRO'){
            this.proInfo = res[i];
          }
          if(res[i].groupName == 'ECN'){
            this.ecnInfo = res[i];
          }
          if(res[i].groupName == 'Cent'){
            this.centInfo = res[i];
          }
          if(res[i].groupName == 'STP'){
            this.stpInfo = res[i];
          }
        }
        callback && callback(res);
        // this.getSmallDetailsGroupList();
      })
    },
    getSmallDetailsGroupList(callback){
      queryGroupDetailAll().then((res) => {
        for (let i = 0; i < res.content.length; i++) {
          if(res.content[i].groupName == 'STD'){
            if(this.stdInfo.id){
              this.stdInfo.groupDetail = res.content[i].groupDetail;
            }
          }
          if(res.content[i].groupName == 'PRO'){
            if(this.proInfo.id){
              this.proInfo.groupDetail = res.content[i].groupDetail;
            }
          }
          if(res.content[i].groupName == 'ECN'){
            if(this.ecnInfo.id){
              this.ecnInfo.groupDetail = res.content[i].groupDetail;
            }
          }
          if(res.content[i].groupName == 'Cent'){
            if(this.centInfo.id){
              this.centInfo.groupDetail = res.content[i].groupDetail;
            }
          }
          if(res.content[i].groupName == 'STP'){
            if(this.stpInfo.id){
              this.stpInfo.groupDetail = res.content[i].groupDetail;
            }
          }
        }
        callback && callback(res);
      })
    },
    close(){
      this.$refs.form.clearValidate();
      this.$emit('close');
    },
    reset(){
      this.form = {
        agencyId:'',
        name: '',
        stdName: '',
        proName: '',
        ecnName: '',
        centName: '',
        stpName: '',
        defaultGroup: '',
      };
      this.agencyId = '';
    },
    cancel(){
      this.close();
    },
    sureHandle() {
      this.$refs.form.validate((valid) => {
        if(valid){
          console.log('pass');
          // ajax
          let params = {
            levelName: this.form.name,
            defaultGroupId: this.form.defaultGroup,
            custId: this.agencyId
          }
          let custGroups = [];

          // id
          params.id = this.curLinkId;
          for (let i = 0; i < this.tCustGroupList.length; i++) {
            if(this.stdInfo.id){
              if(this.tCustGroupList[i].groupId == this.stdInfo.id){
                custGroups.push({
                  id: this.tCustGroupList[i].id,
                  groupId: this.stdInfo.id,
                  groupDetailId: this.form.stdName,
                })
              }
            }

            if(this.proInfo.id){
              if(this.tCustGroupList[i].groupId == this.proInfo.id){
                custGroups.push({
                  id: this.tCustGroupList[i].id,
                  groupId: this.proInfo.id,
                  groupDetailId: this.form.proName,
                })
              }
            }

            if(this.ecnInfo.id){
              if(this.tCustGroupList[i].groupId == this.ecnInfo.id){
                custGroups.push({
                  id: this.tCustGroupList[i].id,
                  groupId: this.ecnInfo.id,
                  groupDetailId: this.form.ecnName,
                })
              }
            }
            if(this.centInfo.id){
              if(this.tCustGroupList[i].groupId == this.centInfo.id){
                custGroups.push({
                  id: this.tCustGroupList[i].id,
                  groupId: this.centInfo.id,
                  groupDetailId: this.form.centName,
                })
              }
            }
            if(this.stpInfo.id){
              if(this.tCustGroupList[i].groupId == this.stpInfo.id){
                custGroups.push({
                  id: this.tCustGroupList[i].id,
                  groupId: this.stpInfo.id,
                  groupDetailId: this.form.stpName,
                })
              }
            }
          }
          params.custGroups = custGroups;
          editCustomerLink(params).then((res) => {
            this.$refs.form.clearValidate();
            this.$notify({
              message: '编辑成功',
              type: 'success'
            });
            this.reset();
            this.$emit('do-success');
          })
        }else {
          console.log('no-pass');
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.addlinks-dialog {
  .el-select {
    width: 100%;
  }
}
</style>
